<template>
    <div class="resources-content">
        <div class="select-wrapper">
            <div class="select-left">
                <el-cascader v-model="specializedValue" style="width: 300px;padding-right: 10px;" :props="keyObj" :options="specializedList"
                             @change="changeProjectsClass" clearable placeholder="请选择分类"></el-cascader>
                <el-input v-model="searchVal" placeholder="请输入名称" style="width: 300px;" class="search-btns">
                    <el-button slot="append" icon="el-icon-search" @click="getProjectsList"></el-button>
                </el-input>
            </div>
        </div>
<!--        <div class="type-wrapper">-->
<!--            <div class="type-name">资源类型：</div>-->
<!--            <div class="type-list">-->
<!--                <div class="type-item" :class="{current:typeCurrent === 0}" @click="toggleTypeCur(0)">全部</div>-->
<!--                <div class="type-item" :class="{current:typeCurrent === projectsItem.resource_type_id}"-->
<!--                     v-for="projectsItem in projectsTypeList" :key="projectsItem.resource_type_id"-->
<!--                     @click="toggleTypeCur(projectsItem.resource_type_id)">{{projectsItem.resource_type_name}}-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="table-wrapper">
            <!--组长写的表格-->
            <div class="d-table">
                <div class="d-thead">
                    <div class="d-tr">
                        <div class="d-th">
                            <div class="cell">详情</div>
                        </div>
                        <div class="d-th">
                            <div class="cell">上传时间</div>
                        </div>
                        <div class="d-th-80">
                            <div class="cell">操作</div>
                        </div>
                    </div>
                </div>
                <div class="d-tbody">
                    <el-scrollbar style="height: 100%">
                        <draggable :list="projectsDragData" :group="{name: 'resources', pull: 'clone', put: false }"
                                   :sort="false" :move="onMove" @end="onEnd">
                            <div class="d-tr" v-for="(item, index) in projectsDragData" :key="index">
                                <div class="d-td cell" v-if="mode === '0'">
                                    <el-checkbox v-model="checkedList" :label="item.id" :disabled="!curIndex">
                                        {{item.project_title}}
                                    </el-checkbox>
                                </div>
                                <div class="d-td" v-else>
                                    <div class="cell">{{item.project_title}}</div>
                                </div>
                                <div class="d-td">
                                    <div class="cell">{{item.create_time}}</div>
                                </div>
                                <div class="d-td-80">
                                    <router-link target="_blank"
                                                 :to="{path: '/admin/index/projectDetail', query: {id: item.id}}">
                                        <el-button size="small">预览</el-button>
                                    </router-link>
                                </div>
                            </div>
                        </draggable>
                    </el-scrollbar>
                </div>
            </div>
            <el-pagination class="pages-right" :current-page="currentPage" layout="prev, pager, next, jumper"
                           :total="totalPages" @current-change="handleCurrentChange">
            </el-pagination>
            <div class="one-import" v-if="mode === '0' && curIndex">
                <el-button type="primary" size="small" @click="selectProjects">一键添加</el-button>
            </div>
        </div>
<!--        <el-pagination class="pages-right" :current-page="currentPage" layout="prev, pager, next, jumper"-->
<!--                       :total="totalPages" @current-change="handleCurrentChange">-->
<!--        </el-pagination>-->
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import _ from "underscore";

    export default {
        name: 'MaterialProject',
        props: ['mode', 'curIndex'],
        components: {
            draggable,
        },
        data() {
            return {
                specializedValue: [],
                searchVal:"",
                specializedList: [],
                keyObj: {
                    label: 'rc_name',
                    value: 'rc_id',
                    children: 'children',
                    // checkStrictly: true
                },
                typeCurrent: 0,
                projectsTableData: [],
                projectsDragData: [],
                page: 1,
                currentPage: 1,
                totalPages: 0,
                showDialogMp4: false,
                videoSrc: '',

                dialogFormVisible: false,
                editingRow: null,
                projectsTypeList: [],
                formLabelWidth: '120px',
                rules: {
                    resource_title: [
                        {required: true, message: '请输入资源标题', trigger: 'blur'}
                    ],
                    resource_content: [
                        {required: true, message: '请输入简介', trigger: 'blur'}
                    ],
                    file_preview_path: [
                        {required: true, message: '请选择文件', trigger: 'change'}
                    ],
                },
                editCurrentIndex: null,
                uploadDialogStatus: false,
                checkedList: []
            }
        },
        created() {
            this.getSpecializedList();
            this.getProjectType();
            this.getProjectsList();
        },
        methods: {
            formatList(list) {
                let array = [];
                for (let i = 0; i < list.length; i++) {
                    let item = list[i];
                    if (item.children && item.children.length > 0) {
                        let value = {
                            rc_id: item.rc_id,
                            rc_name: item.rc_name,
                            children: this.formatList(item.children)
                        }
                        array.push(value);
                    } else {
                        let value = {};
                        value.rc_id = item.rc_id;
                        value.rc_name = item.rc_name;
                        array.push(value);
                    }
                }
                return array;
            },
            initChecks() {
                this.checkedList = [];
            },
            selectProjects(val) {
                let arrTmp = _.filter(this.projectsTableData, (item) => {
                    item.name = item.project_title;
                    item.type = 5;
                    item.children = [];
                    return this.checkedList.indexOf(item.id) !== -1;
                });
                this.$emit('reprojects', arrTmp);
                this.initChecks();
            },
            getSpecializedList() {
                this.$http.axiosGetBy(this.$api.resourceClassList, {type: 4}, (res) => {
                    if (res.code === 200) {
                        this.specializedList = this.formatList(res.list);
                    }
                }, (err) => {
                    console.log(err);
                });
            },
            getSpecializedListData(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].children.length === 0) {
                        delete data[i].children;
                    } else {
                        this.getSpecializedListData(data[i].children);
                    }
                }
                return data;
            },
            getProjectType() {
                this.$http.axiosGet(this.$api.resourceTypeList, (res) => {
                    if (res.code === 200) {
                        this.projectsTypeList = res.list
                    }
                })
            },
            changeProjectsClass() {
                this.getProjectsList();
            },
            getProjectsList() {
                let resource_class_id = '';
                let specializedValueLength = this.specializedValue.length;
                if (specializedValueLength > 0) {
                    resource_class_id = this.specializedValue[specializedValueLength - 1];
                }
                let resource_type_id = this.typeCurrent == 0 ? '' : this.typeCurrent;
                let data = {
                    resource_type_id: resource_type_id,
                    resource_class_id: resource_class_id,
                    page: this.page,
                    project_title: this.searchVal,
                }
                if (resource_class_id == '') {
                    delete data.resource_class_id
                }
                if (resource_type_id == '') {
                    delete data.resource_type_id
                }
                if(this.searchVal == '') {
                    delete data.project_title
                }
                this.$http.axiosGetBy(this.$api.AdminProjectList, data, (res) => {
                    if (res.code === 200) {
                        this.totalPages = res.data.total;
                        this.projectsTableData = res.data.list;
                        this.getDragData();
                        if (this.totalPages && this.projectsTableData.length === 0) {
                            this.page--;
                            this.getProjectsList();
                        }
                    }
                })
            },
            getDragData() {
                this.projectsDragData = this.projectsTableData;
                let len = this.projectsDragData.length;
                for (let i = 0; i < len; i++) {
                    this.projectsDragData[i].name = this.projectsDragData[i].project_title;
                    this.projectsDragData[i].type = 5;
                    this.projectsDragData[i].children = [];
                    delete this.projectsDragData[i].project_cover
                }
            },
            uploadProjectsBtn() {
                this.$router.push('/admin/index/addProject')
            },
            toggleTypeCur(id) {
                this.typeCurrent = id;
                this.getProjectsList();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getProjectsList()
            },
            onMove(evt) {
                if (evt.to.className === 'move-area') {
                    let moveElements = document.getElementsByClassName('move-area');
                    for (let i = 0; i < moveElements.length; i++) {
                        moveElements[i].className = 'move-area';
                    }
                    evt.to.className = 'move-area move-selected';
                }
            },
            onEnd(evt) {
                let elementList = document.getElementsByClassName('move-selected');
                for (let i = 0; i < elementList.length; i++) {
                    elementList[i].className = 'move-area';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .select-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .select-left {
            display: flex;
        }
    }

    .type-wrapper {
        margin-top: 10px;
        display: flex;

        .type-name {
            width: 100px;
            margin-top: 10px;
            line-height: 30px;
            color: #000;
        }

        .type-list {
            .type-item {
                display: inline-block;
                padding: 4px 10px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 4px;
                margin: 10px 10px 0 0;
                font-size: 14px;
                border: 1px solid transparent;
                cursor: pointer;
                color: #666;
                transition: all .3s ease-in;

                &.current {
                    color: #409eff;
                    background: #ecf5ff;
                    border: 1px solid #d9ecff;
                }

                &:hover {
                    color: #409eff;
                }
            }
        }
    }

    .table-content {
        .img-thumb {
            width: 60px;
            height: 60px;
            overflow: hidden;

            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .detail-box {
            display: flex;
            justify-content: center;

            img {
                max-width: 50px;
                max-height: 50px;
            }

            .goods-name {
                line-height: 4;
            }
        }
    }

    .video-dialog {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .5);
        z-index: 9;

        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 30px;
            width: 30px;
            height: 30px;
            line-height: 1;
            background: rgba(0, 0, 0, .7);
            color: #fff;
            cursor: pointer;
            transition: all .3s;

            &:hover {
                color: #409EFF;
            }
        }

        .video-content {
            width: 50%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 3px solid #111;

            video {
                outline: none;
                display: block;
            }
        }
    }

    .upload-btn {
        position: relative;

        input {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
</style>
