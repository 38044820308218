<template>
    <div class="exam-paper-content">
        <div class="ep-acts">
            <div class="ep-act-search">
                <div class="class-tit">
                    <span class="class-txt">专业分类：</span>
                </div>
                <el-cascader
                        v-model="classVal"
                        :options="classOptions"
                        :props="classProps"
                        @change="handleClass"
                        class="classes-list" clearable></el-cascader>
                <el-input v-model="searchVal" placeholder="请输入试卷名称" class="search-btns">
                    <el-button slot="append" icon="el-icon-search" @click="searchAct"></el-button>
                </el-input>
            </div>
            <div class="ep-add">
            </div>
        </div>
        <div class="ep-list">
            <div class="table-data">
                <div class="table-wrapper">
                    <!--自己写的表格-->
                    <div class="d-table">
                        <div class="d-thead">
                            <div class="d-tr">
                                <div class="d-th">
                                    <div class="cell">试卷名称</div>
                                </div>
                                <div class="d-th-100">
                                    <div class="cell">创建者</div>
                                </div>
                                <div class="d-th">
                                    <div class="cell">时间</div>
                                </div>
                                <div class="d-th-100">
                                    <div class="cell">操作</div>
                                </div>
                            </div>
                        </div>
                        <div class="d-tbody">
                            <el-scrollbar style="height: 100%">
                                <draggable :list="examPapers" :group="{name: 'resources', pull: 'clone', put: false }" :sort="false" :move="onMove" @end="onEnd">
                                    <div class="d-tr" v-for="(item, index) in examPapers" :key="index">
                                        <div class="d-td">
                                            <div class="cell" v-if="mode === '0'">
                                                <el-checkbox v-model="checkedList" :label="item.resource_id" :disabled="!curIndex">{{item.name}}</el-checkbox>
                                            </div>
                                            <div class="cell" v-else>{{item.name}}</div>
                                        </div>
                                        <div class="d-td-100 resourceTit"><div class="cell">{{item.admin_name}}</div></div>
                                        <div class="d-td"><div class="cell">{{item.create_time}}</div></div>
                                        <div class="d-td-100 d-td-right">
                                            <router-link target="_blank" :to="{path: '/exampaper/preview', query: {id: item.resource_id}}">
                                                <el-button size="small">预览</el-button>
                                            </router-link>
                                        </div>

                                    </div>
                                </draggable>
                            </el-scrollbar>
                        </div>
                    </div>
                    <div class="exampaper-import" v-if="mode === '0' && curIndex">
                        <el-button type="primary" size="small" @click="selectExamPaper">一键添加</el-button>
                    </div>
                </div>
                <div class="page-block">
                    <el-pagination
                            layout="total, prev, pager, next, jumper"
                            :current-page.sync="currentPage"
                            :page-size="limit"
                            @current-change="handleCurrentChange"
                            :total="totalNum">
                    </el-pagination>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import _ from "underscore";
    import draggable from 'vuedraggable';
    export default {
        name: "MaterialExam",
        created() {
            this.setInit();
        },
        props: ['mode', 'curIndex'],
        data() {
            return {
                classVal: [],
                classOptions: [],
                classId: null,
                searchVal: '',
                classProps: {
                    value: 'rc_id',
                    label: 'rc_name'
                },
                currentPage: 1,
                limit: 10,
                examPapers: [],
                totalNum: 0,
                checkedList: []
            }
        },
        components: {
            draggable
        },
        methods: {
            initCheckeds() {
                this.checkedList = [];
            },
            selectExamPaper() {
                let arrTmp = _.filter(this.examPapers, (item) => {
                    return this.checkedList.indexOf(item.resource_id) !== -1;
                });
                this.$emit('reexampapers', arrTmp);
                this.initCheckeds();
            },
            preView(id) {
                console.log(id);
            },
            searchAct() {
                this.searchVal = this.searchVal.trim();
                this.getExamPapers();
            },
            setInit() {
                this.getClasses();
                this.getExamPapers();
            },
            handleClass() {
                this.classId = this.classVal[this.classVal.length - 1];
                this.getExamPapers();
            },
            getClasses() {
                let params = {
                    type: 1
                }
                this.$http.axiosGetBy(this.$api.resourceClassList, params, (res) => {
                    this.classOptions = res.list;
                    _.map(this.classOptions, (item) => {
                        if (item.children.length) {
                            _.map(item.children, (item_a) => {
                                if (item_a.children.length) {
                                    _.map(item_a.children, (item_b) => {
                                        if (item_b.children.length === 0) {
                                            delete item_b.children;
                                        }
                                    });
                                } else {
                                    delete item_a.children;
                                }
                            });
                        } else {
                            delete item.children;
                        }
                    });
                }, (err) => {
                    console.log(err);
                })
            },
            getExamPapers() {
                let params = {
                    limit: this.limit,
                    current_page: this.currentPage
                };
                if (this.classId) {
                    params.rc_id = this.classId;
                }
                if (this.searchVal) {
                    params.paper_name = this.searchVal;
                }
                this.$http.axiosGetBy(this.$api.screenTestPaper, params, (res) => {
                    if (res.code === 200) {
                        this.examPapers = _.map(res.data.data, (item) =>{
                            item.name = item.paper_name;
                            item.resource_id = item.id;
                            item.type = 4;
                            item.children = [];
                            return item;
                        });
                        this.totalNum = res.data.totalCount;
                    }
                }, (err) => {
                    console.log(err);
                });
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getExamPapers();
            },
            onMove(evt) {
                if (evt.to.className === 'move-area') {
                    let moveElements = document.getElementsByClassName('move-area');
                    for (let i=0; i<moveElements.length; i++) {
                        moveElements[i].className = 'move-area';
                    }
                    evt.to.className = 'move-area move-selected';
                }
            },
            onEnd(evt) {
                let elementList = document.getElementsByClassName('move-selected');
                for(let i=0; i<elementList.length; i++) {
                    elementList[i].className = 'move-area';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .exam-paper-content {
        * {
            box-sizing: border-box;
        }
        height: 100%;
        width: 100%;
        .ep-acts {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;
            .class-tit, .classes-list {
                margin-right: 5px;
            }
            .ep-act-search {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex: 1;
                .search-btns {
                    width: 300px;
                }
            }
        }
    }
    .act-btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .ep-list {
        height: calc(100% - 80px);
        .table-data {
            position: relative;
            height: calc(100% - 42px);
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .exampaper-import {
                position: absolute;
                left: 10px;
                bottom: -42px;
                z-index: 10;
            }

        }
        .page-block {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .d-table {
            border-right: 1px solid #EBEEF5;

        }
    }


</style>