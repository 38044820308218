<template>
    <div class="batch-main">
        <ul class="batch-chapters">
            <li class="batch-chapter-item" v-for="(chapterEle, cIndex) in children">
                <div class="batch-cahter-content item-main">
                    <div class="chapter-item-text item-text">
                        <div class="editor-status" v-if="editIndex === cIndex">
                            <div class="item-title-editor">第{{cIndex+1}}章：</div>
                            <el-input v-model="name" :ref="'input_'+cIndex" @blur="checkSection(cIndex)" placeholder="请输入章"></el-input>
                        </div>
                        <span class="chapter-title item-title" v-else>第{{cIndex+1}}章：{{chapterEle.name}}</span>
                    </div>
                    <div class="item-actions">
                        <div class="icon-btn-expand">
                            <el-dropdown  trigger="click" @command="handleCommand">
                            <span class="el-dropdown-link">
                                <i class="el-icon-plus"></i>添加
                            </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="cIndex">添加节</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <a class="icon-btn" @click="editName(cIndex)">
                        <span class="actions-btn">
                            <i class="el-icon-edit"></i>编辑
                        </span>
                        </a>
                        <template>
                            <el-popconfirm
                                    title="确定删除吗?"
                                    @confirm="delSt(cIndex)"
                                    @onConfirm="delSt(cIndex)"
                            >
                                <a class="icon-btn" slot="reference">
                                <span class="actions-btn">
                                    <i class="el-icon-delete"></i>删除
                                </span>
                                </a>
                            </el-popconfirm>
                        </template>
                        <a class="icon-btn" @click="expandFunc(cIndex)">
                            <span class="actions-btn" v-if="chapterEle.expand">
                                <i class="el-icon-caret-top"></i>收起
                            </span>
                                <span class="actions-btn" v-else>
                                <i class="el-icon-caret-bottom"></i>展开
                            </span>
                        </a>
                    </div>
                </div>
                <div class="batch-chapter-child" v-if="chapterEle.expand">
                    <ul class="batch-sections">
                        <li class="batch-sections-item" v-for="(sectionEle, sIndex) in chapterEle.children">
                            <div :class="index === cIndex+'|'+sIndex ? 'batch-section-content item-main active': 'batch-section-content item-main'" @click.stop="setSecIndex(cIndex, sIndex)">
                                <div class="section-item-text item-text">
                                    <div class="editor-status" v-if="editIndex === cIndex+'_'+sIndex">
                                        <div class="item-title-editor">第{{sIndex+1}}节：</div>
                                        <el-input v-model="name" :ref="'input_'+cIndex+'_'+sIndex" @blur="checkSection(cIndex+'_'+sIndex)" placeholder="请输入节"></el-input>
                                    </div>
                                    <span class="section-title item-title" v-else>第{{sIndex+1}}节：{{sectionEle.name}}</span>
                                </div>
                                <div class="item-actions">
                                    <a class="icon-btn" @click.stop="editName(cIndex+'_'+sIndex)">
                                        <span class="actions-btn"><i class="el-icon-edit"></i>编辑</span>
                                    </a>
                                    <template>
                                        <el-popconfirm
                                                title="确定删除吗?"
                                                @confirm="delSt(cIndex+'_'+sIndex)"
                                                @onConfirm="delSt(cIndex+'_'+sIndex)"
                                        >
                                            <a class="icon-btn" slot="reference">
                                                <span class="actions-btn"><i class="el-icon-delete"></i>删除</span>
                                            </a>
                                        </el-popconfirm>
                                    </template>
                                </div>
                            </div>
                            <div class="batch-section-child">
                                <ul class="batch-resources">
                                    <li class="batch-resource-item" v-for="(resourceEle, rIndex) in sectionEle.children">
                                        <div v-if="resourceEle !== null">
                                            <div class="batch-resource-content item-main" v-if="resourceEle.type === 3 || resourceEle.type === '3'">
                                                <div class="resource-item-text item-text">
                                                    <div style="display: flex;align-items: center;">
                                                        <i v-html="fileTypeF(resourceEle.resource_file_type)"></i>
                                                    </div>
                                                    <div class="editor-status" v-if="editIndex === cIndex+'_'+sIndex+'_'+rIndex">
                                                        <div class="item-title-editor">资源{{rIndex+1}}：</div>
                                                        <el-input v-model="name" :ref="'input_'+cIndex+'_'+sIndex+'_'+rIndex" @blur="checkSection(cIndex+'_'+sIndex+'_'+rIndex)" placeholder="请输入"></el-input>
                                                    </div>
                                                    <span v-else class="resource-title item-title">资源{{rIndex+1}}：{{resourceEle.name}}</span>
                                                </div>
                                                <div class="item-actions">
                                                    <a class="icon-btn" @click.stop="editName(cIndex+'_'+sIndex+'_'+rIndex)">
                                                        <span class="actions-btn"><i class="el-icon-edit"></i>编辑</span>
                                                    </a>
                                                    <a class="icon-btn" @click="viewPre(resourceEle)"><span class="actions-btn"><i class="el-icon-view"></i>预览</span></a>
                                                    <template>
                                                        <el-popconfirm
                                                                title="确定删除吗?"
                                                                @confirm="delSt(cIndex+'_'+sIndex+'_'+rIndex)"
                                                                @onConfirm="delSt(cIndex+'_'+sIndex+'_'+rIndex)"
                                                        >
                                                            <a class="icon-btn" slot="reference"><span class="actions-btn"><i class="el-icon-delete"></i>删除</span></a>
                                                        </el-popconfirm>
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="batch-resource-content item-main" v-if="resourceEle.type === 4 || resourceEle.type === '4'">
                                                <div class="resource-item-text item-text">
                                                    <div style="display: flex;align-items: center;">
                                                        <i class="el-icon-s-claim"></i>
                                                    </div>
                                                    <div class="editor-status" v-if="editIndex === cIndex+'_'+sIndex+'_'+rIndex">
                                                        <div class="item-title-editor">测试{{rIndex+1}}：</div>
                                                        <el-input v-model="name" :ref="'input_'+cIndex+'_'+sIndex+'_'+rIndex" @blur="checkSection(cIndex+'_'+sIndex+'_'+rIndex)" placeholder="请输入"></el-input>
                                                    </div>
                                                    <span v-else class="resource-title item-title">测试{{rIndex+1}}：{{resourceEle.name}}</span>
                                                </div>
                                                <div class="item-actions">
                                                    <a class="icon-btn" @click.stop="editName(cIndex+'_'+sIndex+'_'+rIndex)">
                                                        <span class="actions-btn"><i class="el-icon-edit"></i>编辑</span>
                                                    </a>
                                                    <router-link target="_blank" class="icon-btn" :to="{path: '/exampaper/preview', query: {id: resourceEle.id}}">
                                                    <span class="actions-btn">
                                                        <i class="el-icon-view"></i>预览
                                                    </span>
                                                    </router-link>
                                                    <template>
                                                        <el-popconfirm
                                                                title="确定删除吗?"
                                                                @confirm="delSt(cIndex+'_'+sIndex+'_'+rIndex)"
                                                                @onConfirm="delSt(cIndex+'_'+sIndex+'_'+rIndex)"
                                                        >
                                                            <a class="icon-btn" slot="reference"><span class="actions-btn"><i class="el-icon-delete"></i>删除</span></a>
                                                        </el-popconfirm>
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="batch-resource-content item-main" v-if="resourceEle.type === 5 || resourceEle.type === '5'">
                                                <div class="resource-item-text item-text">
                                                    <div style="display: flex;align-items: center;">
                                                        <i class="el-icon-menu"></i>
                                                    </div>
                                                    <div class="editor-status" v-if="editIndex === cIndex+'_'+sIndex+'_'+rIndex">
                                                        <div class="item-title-editor">项目{{rIndex+1}}：</div>
                                                        <el-input v-model="name" :ref="'input_'+cIndex+'_'+sIndex+'_'+rIndex" @blur="checkSection(cIndex+'_'+sIndex+'_'+rIndex)" placeholder="请输入"></el-input>
                                                    </div>
                                                    <span v-else class="resource-title item-title">项目{{rIndex+1}}：{{resourceEle.name}}</span>
                                                </div>
                                                <div class="item-actions">
                                                    <a class="icon-btn" @click.stop="editName(cIndex+'_'+sIndex+'_'+rIndex)">
                                                        <span class="actions-btn"><i class="el-icon-edit"></i>编辑</span>
                                                    </a>
                                                    <router-link target="_blank" class="icon-btn" :to="{path: '/admin/index/projectDetail', query: {id: resourceEle.id}}">
                                                    <span class="actions-btn">
                                                        <i class="el-icon-view"></i>预览
                                                    </span>
                                                    </router-link>
                                                    <template>
                                                        <el-popconfirm
                                                                title="确定删除吗?"
                                                                @confirm="delSt(cIndex+'_'+sIndex+'_'+rIndex)"
                                                                @onConfirm="delSt(cIndex+'_'+sIndex+'_'+rIndex)"
                                                        >
                                                            <a class="icon-btn" slot="reference"><span class="actions-btn"><i class="el-icon-delete"></i>删除</span></a>
                                                        </el-popconfirm>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "lessonBatchTemplate",
        props: {
            children: {
                required: true,
                type: Array
            },
            index: {
                required: true
            }
        },
        data() {
            return {
                name: '',
                currentSec: null,
                editIndex: null,
            }
        },
        methods: {
            expandFunc(index) {
                let tmp = this.children[index];
                if (tmp.expand) {
                    tmp.expand = false;
                } else {
                    tmp.expand = true;
                }
                this.$set(this.children, index, tmp);
            },
            cursorFocus(index) {
                setTimeout(() => {
                    this.$refs[`${index}`][0].focus();
                }, 500);
            },
            editName(index) {
                this.editIndex = index;
                this.cursorFocus(`input_${index}`);
                if (typeof index === "number") {
                    this.name = this.children[index].name;
                    return;
                }
                let arrTmp = index.split('_');
                if(arrTmp.length == 2){
                    this.name = this.children[arrTmp[0]].children[arrTmp[1]].name;
                    return;
                }
                if(arrTmp.length == 3){
                    this.name = this.children[arrTmp[0]].children[arrTmp[1]].children[arrTmp[2]].name;
                    return;
                }
            },
            checkSection(index) {
                let name = this.name.trim();
                if (name.length === 0) {
                    this.$message({
                        type: 'warning',
                        message: '节标题不能为空,请设置节标题！',
                        duration: 1000,
                        onClose: () => {
                            this.name = '';
                            this.$refs[`input_${index}`][0].focus();
                        }
                    });
                    return;
                }
                if (typeof index === "number") {
                    let tmp = this.children[index];
                    tmp.name = name;
                    this.$set(this.children, index, tmp);
                } else {
                    let indexTmp = index.split("_");
                    if(indexTmp.length == 2){
                        this.children[indexTmp[0]].children[indexTmp[1]].name = name;
                    }else if(indexTmp.length == 3){
                        this.children[indexTmp[0]].children[indexTmp[1]].children[indexTmp[2]].name = name;
                    }
                }
                this.editIndex = null;
                this.name = '';
            },
            setSecIndex(cindex, sindex) {
                this.$emit('selectSec', `${cindex}|${sindex}`);
            },
            delSt(index) {
                console.log({ index });
                if (typeof index === "number") {
                    this.children.splice(index, 1);
                } else {
                    let indexTmp = index.split('_');
                    if (indexTmp.length === 2) {
                        this.children[indexTmp[0]].children.splice(indexTmp[1], 1);
                        return;
                    }
                    this.children[indexTmp[0]].children[indexTmp[1]].children.splice(indexTmp[2], 1);
                }
            },
            handleCommand(command) {
                let sectionTmp = {
                    name: '',
                    type: 2,
                    children: []
                }
                if (!this.children[command].expand) {
                    this.$message({
                        type: 'warning',
                        message: '请先展开当前章下的所有节',
                        showClose: true
                    });
                    return;
                }
                this.children[command].children.unshift(sectionTmp);
                this.editIndex = `${command}_0`;
                this.cursorFocus(`input_${command}_0`);
            },
            fileTypeF(type) {
                let html = '';
                switch (type) {
                    case "mp4":
                        html = "<i class='iconfont'>&#xe682;</i>";
                        break;
                    case "pptx":
                        html = "<i class='iconfont'>&#xe63a;</i>";
                        break;
                    case "pdf":
                        html = "<i class='iconfont'>&#xe65b;</i>";
                        break;
                    default:
                        html = "<i class='iconfont'>&#xe640;</i>";
                        break;
                }
                return html;
            },
            viewPre(el) {
                if (el.resource_file_type.toLowerCase() === 'mp4') {
                    window.open(el.file_preview_path);
                } else {
                    let url = process.env.NODE_ENV === 'development' ? this.$config.baseUrl : '';
                    url += this.$api.pdfPreview + el.resource_id
                    window.open(url);
                }

            }
        }
    }
</script>

<style scoped lang="scss">
    .batch-main {
        ul, li {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        .item-main {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 42px;
            margin-bottom: 15px;
            border: 1px solid #c1c1c1;
            .item-text {
                display: flex;
                flex: 1;
                .item-title-editor {
                    min-width: 60px;
                    max-width: 100px;
                }
                padding-left: 10px;
                i {
                    font-size: 16px;
                    margin-right: 5px;
                }
                .editor-status {
                    display: flex;
                    flex: 1;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
            &.active {
                box-shadow: 5px 5px 8px #dddddd;
            }
        }
        .batch-chapters {
            margin-left: 15px;
            .batch-sections {
                margin-left: 15px;
                .batch-resources {
                    margin-left: 15px;
                }
            }
        }
        .item-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            .icon-btn-expand, .icon-btn {
                cursor: pointer;
                font-size: 14px;
                padding: 0 12px;
                i {
                    margin-right: 5px;
                }

            }
        }
    }


</style>