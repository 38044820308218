<template>
    <div class="resources-content">
        <div class="select-wrapper">
            <div class="select-left">
                <el-cascader v-model="specializedValue" class="re-class-list" :props="keyObj" :options="specializedList" @change="changeResourcesClass" placeholder="请选择分类" clearable></el-cascader>
            </div>
            <div class="select-btn">
                <el-input v-model="searchValue" @keydown.enter.native="searchRes"  class="res-search-value" placeholder="可按回车键搜索" clearable></el-input>
            </div>
        </div>
        <div class="type-wrapper">
            <div class="type-name">资源类型：</div>
            <div class="type-list">
                <div class="type-item" :class="{current:typeCurrent === resourcesItem.resource_type_id}" v-for="resourcesItem in resourcesTypeList" :key="resourcesItem.resource_type_id" @click="toggleTypeCur(resourcesItem.resource_type_id)">{{resourcesItem.resource_type_name}}</div>
            </div>
        </div>
        <div class="table-wrapper">
            <!--自己写的表格-->
            <div class="d-table">
                <div class="d-thead">
                    <div class="d-tr">
                        <div class="d-th-80">
                            <div class="cell">类型</div>
                        </div>
                        <div class="d-th">
                            <div class="cell">资源标题</div>
                        </div>
                        <div class="d-th">
                            <div class="cell">简介</div>
                        </div>
                        <div class="d-th-100">
                            <div class="cell">时长/页数</div>
                        </div>
                        <div class="d-th-80">
                            <div class="cell">操作</div>
                        </div>
                    </div>
                </div>
                <div class="d-tbody">
                <el-scrollbar style="height: 100%">
                    <draggable :list="resourcesTableData" :group="{name: 'resources', pull: 'clone', put: false }" :sort="false" :move="onMove" @end="onEnd">
                        <div class="d-tr" v-for="(item, index) in resourcesTableData" :key="index">
                            <div class="d-td-80 cell" v-if="mode === '0'">
                                <el-checkbox v-model="checkedList" :label="item.resource_id" :disabled="!curIndex">{{item.resource_file_type}}</el-checkbox>
                            </div>
                            <div class="d-td-80" v-else>
                                <div class="cell" >{{item.resource_file_type}}</div>
                            </div>
                            <div class="d-td resourceTit"><div class="cell">{{item.name}}</div></div>
                            <div class="d-td"><div class="cell">{{item.resource_content}}</div></div>
                            <div class="d-td-100"><div class="cell">{{item.resource_page}}</div></div>
                            <div class="d-td-80">
                                <el-button size="small" @click="previewResources(item)">预览</el-button>
                            </div>
                        </div>
                    </draggable>
                </el-scrollbar>
                </div>
            </div>
            <el-pagination class="pages-right" :current-page="currentPage" layout="prev, pager, next, jumper" :total="totalPages" @current-change="handleCurrentChange">
            </el-pagination>
            <div class="one-import" v-if="mode === '0' && curIndex">
                <el-button type="primary" size="small" @click="selectMaterials">一键添加</el-button>
            </div>
        </div>

        <div class="video-dialog" v-show="showDialogMp4">
            <i class="iconfont close-icon" @click="closeDialogMp4">&#xe8e7;</i>
            <div class="video-content">
                <video id="videoIns" width="100%" height="auto" :src="videoSrc" autoplay controls></video>
            </div>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import _ from 'underscore';

    export default {
        data() {
            return {
                specializedValue: [],
                specializedList: [],
                keyObj: {
                    label: 'rc_name',
                    value: 'rc_id',
                    children: 'children',
                },
                typeCurrent: 0,
                resourcesTableData: [],
                page: 1,
                currentPage: 1,
                totalPages: 0,
                showDialogMp4: false,
                videoSrc: '',
                editingRow: null,
                resourcesTypeList: [],
                formLabelWidth: '120px',
                editCurrentIndex: null,
                searchValue: '',
                checkedList: []
            }
        },
        props: ['mode', 'curIndex'],
        components: {
            draggable,
        },
        created() {
            this.getSpecializedList();
            this.getResourcesType();
            this.getResourcesList();
        },
        methods: {
            initChecks() {
                this.checkedList = [];
            },
            selectMaterials(val) {
                let arrTmp = _.filter(this.resourcesTableData, (item) => {
                    return this.checkedList.indexOf(item.resource_id) !== -1;
                });
                this.$emit('rematerials', arrTmp);
                this.initChecks();
            },
            searchRes() {
                this.getResourcesList();
            },
            getSpecializedList() {
                this.$http.axiosGetBy(this.$api.resourceClassList, { type: 0 },(res) => {
                    if (res.code === 200) {
                        this.specializedList = this.getSpecializedListData(res.list);
                    }
                }, (err) => {
                    console.log(err);
                });
            },
            getSpecializedListData(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].children.length === 0) {
                        delete data[i].children;
                    } else {
                        this.getSpecializedListData(data[i].children);
                    }
                }
                return data;
            },
            getResourcesType() {
                this.$http.axiosGet(this.$api.resourceTypeList, (res) => {
                    if (res.code === 200) {
                        this.resourcesTypeList = res.list;
                        this.resourcesTypeList.unshift({
                            resource_type_id: 0,
                            resource_type_name: '全部'
                        });
                    }
                })
            },
            changeResourcesClass() {
                this.getResourcesList();
            },
            getResourcesList() {
                let params = {
                    resource_type: 0,
                    page: this.page
                };
                let specializedValueLength = this.specializedValue.length;
                if (specializedValueLength > 0) {
                    params.resource_class_id = this.specializedValue[specializedValueLength - 1];
                }
                if (this.searchValue) {
                    params.title = this.searchValue;
                }
                if (this.typeCurrent) {
                    params.resource_type_id = this.typeCurrent;
                }
                this.$http.axiosGetBy(this.$api.resourceList, params, (res) => {
                    if (res.code === 200) {
                        this.totalPages = res.total;
                        this.resourcesTableData = _.map(res.list, (item) => {
                            let val = {};
                            val.resource_id = item.resource_id;
                            val.name = item.resource_title;
                            val.type = 3;
                            val.children = [];
                            val.resource_page = item.resource_page;
                            val.resource_content = item.resource_content;
                            val.resource_file_type = item.resource_file_type.toLowerCase();
                            if (item.resource_file_type.toLowerCase() === 'mp4') {
                                val.file_preview_path = item.file_preview_path;
                            }
                            return val;
                        });
                    }
                })
            },
            search() {
                console.log('搜索')
            },
            toggleTypeCur(id) {
                this.typeCurrent = id;
                this.getResourcesList();
            },
            previewResources(file) {
                if (file.resource_file_type.toLowerCase() === 'mp4') {
                    this.showDialogMp4 = true;
                    this.videoSrc = file.file_preview_path;
                } else {
                    let url = process.env.NODE_ENV === 'development' ? this.$config.baseUrl : '';
                    url += this.$api.pdfPreview + file.resource_id;
                    window.open(url);
                }
            },
            closeDialogMp4() {
                let movieIns = document.getElementById("videoIns");
                movieIns.pause();
                this.showDialogMp4 = false
            },
            changeFile(event) {
                let fileList = event.target.files;
                this.editingRow.file_name = fileList[0].name;
                this.editingRow.file_preview_path = fileList[0];
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getResourcesList()
            },
            onMove(evt) {
                if (evt.to.className === 'move-area') {
                    let moveElements = document.getElementsByClassName('move-area');
                    for (let i=0; i<moveElements.length; i++) {
                        moveElements[i].className = 'move-area';
                    }
                    evt.to.className = 'move-area move-selected';
                }
            },
            onEnd(evt) {
                let elementList = document.getElementsByClassName('move-selected');
                for(let i=0; i<elementList.length; i++) {
                    elementList[i].className = 'move-area';
                }
            }
        }
    }
</script>

<style lang="scss" >
    .select-wrapper {
        .re-class-list {
            width: 300px;
        }
        .res-search-value {
            width: 240px;
        }
    }
    .select-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .type-wrapper {
        margin-top: 20px;
        display: flex;
        .type-name {
            width: 100px;
            margin-top: 10px;
            line-height: 30px;
            color: #000;
        }
        .type-list {
            .type-item {
                display: inline-block;
                padding: 4px 10px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 4px;
                margin: 10px 10px 0 0;
                font-size: 14px;
                border: 1px solid transparent;
                cursor: pointer;
                color: #666;
                transition: all .3s ease-in;
                &.current {
                    color: #409eff;
                    background: #ecf5ff;
                    border: 1px solid #d9ecff;
                }
                &:hover {
                    color: #409eff;
                }
            }
        }
    }
    .table-wrapper {
        position: relative;
        margin-top: 20px;
        .table-content {
            .img-thumb {
                width: 60px;
                height: 60px;
                overflow: hidden;
                img {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        .d-table {
            width: 100%;
            max-height: 550px;
            border: 1px solid #EBEEF5;
            border-right: none;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
            flex: 1;
            max-width: 100%;
            font-size: 14px;
            color: #606266;
            background-color: #FFF;
            .d-tbody {
                height: 497px;
            }
            .d-tr {
                height: 53px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: move;
                .d-th, .d-th-80, .d-th-100 {
                    background-color: rgb(245, 247, 250);
                }
                .d-th-80, .d-th-100, .d-td-80, .d-td-100, .d-th, .d-td {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-right: 1px solid #EBEEF5;
                    border-bottom: 1px solid #EBEEF5;
                }
                .d-th, .d-td {
                    flex: 1;
                    justify-content: flex-start;
                    padding: 0 10px;
                    align-items: center;
                }
                .d-th-80, .d-td-80 {
                    width: 80px;
                }
                .d-th-100, .d-td-100 {
                    width: 100px;
                }
                .d-td-right {
                    border-right: none;
                }
            }
        }
        .one-import {
            position: absolute;
            bottom: 5px;
            left: 10px;
        }
    }
    /*.upload-resources-dialog {*/
        /*.el-dialog {*/
            /*.el-dialog__footer {*/
                /*text-align: center;*/
                /*.el-button+.el-button {*/
                    /*margin-left: 30px;*/
                /*}*/
            /*}*/
        /*}*/
    /*}*/
    .video-dialog {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.5);
        z-index: 9;
        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 30px;
            width: 30px;
            height: 30px;
            line-height: 1;
            background: rgba(0,0,0,.7);
            color: #fff;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: #409EFF;
            }
        }
        .video-content {
            width: 50%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 3px solid #111;
            video {
                outline: none;
                display: block;
            }
        }
    }
    .upload-btn {
        position: relative;
        input {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
    .table-wrapper table {
        width: 100%;
        max-height: 500px;
        border: 1px solid #EBEEF5;
        border-right: none;
        border-bottom: none;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        flex: 1;
        max-width: 100%;
        font-size: 14px;
        color: #606266;
        background-color: #FFF;
        border-spacing: 0;
        thead {
            color: #909399;
            font-weight: 500;
            tr {
                background-color: #FFF;
                height: 44px;
                th {
                    border-bottom: 1px solid #EBEEF5;
                    font-weight: normal;
                    color: rgb(51, 51, 51);
                    background: rgb(245, 247, 250);
                    border-right: 1px solid #EBEEF5;
                }
            }
        }
        tbody {
            tr {
                height: 53px;
                td {
                    border-bottom: 1px solid #EBEEF5;
                    border-right: 1px solid #EBEEF5;
                    text-align: center;
                }
            }
        }
    }
</style>
